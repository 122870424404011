// 获取列表
const getListURL = `${API_CONFIG.baseURL}commentAction!commentList.action?type=1`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}commentAction!exportToExcel.action?type=1`
// 删除
const deleteURL = `${API_CONFIG.baseURL}commentAction!delComment.action`
const deleteBatchURL = `${API_CONFIG.baseURL}commentAction!delCommentOnbatch.action`
// 回复
const replyURL = `${API_CONFIG.baseURL}commentAction!reply.action`

// 查询详情
const queryURL = `${API_CONFIG.baseURL}commentAction!topicDetail.action`

export {
  getListURL,
  exportListURL,
  deleteURL,
  replyURL,
  queryURL,
  deleteBatchURL
}
